/**
 * Created by sky on 2019/3/29.
 */
const constants={
    // 全局遮罩层
    GLOBAL_MODAL_SHOW:'GLOBAL_MODAL_SHOW',
    GLOBAL_MODAL_UNSHOW:'GLOBAL_MODAL_UNSHOW',

    // 管理员
    ADMIN_LOGIN:'ADMIN_LOGIN',
    ADMIN_LOGIN_SUCCESS:'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_STATUS_CHECK:'ADMIN_LOGIN_STATUS_CHECK',
    ADMIN_LOGIN_STATUS_CHECK_SUCCESS:'ADMIN_LOGIN_STATUS_CHECK_SUCCESS',
    ADMIN_LOGIN_OUT:'ADMIN_LOGIN_OUT',
    ADMIN_LOGIN_OUT_SUCCESS:'ADMIN_LOGIN_OUT_SUCCESS',

    //总管账号
    //获取店铺列表
    SHOP_GET:'SHOP_GET',
    SHOP_GET_SUCCESS:'SHOP_GET_SUCCESS',

    ALL_ADMIN_AUTH_GET:'ALL_ADMIN_AUTH_GET',
    ALL_ADMIN_AUTH_GET_SUCCESS:'ALL_ADMIN_AUTH_GET_SUCCESS',

    //上传小程序
    ALL_ADMIN_UPLOAD_XCX:'ALL_ADMIN_UPLOAD_XCX',
    ALL_ADMIN_UPLOAD_XCX_SUCCESS:'ALL_ADMIN_UPLOAD_XCX_SUCCESS',
    //检测上传状态
    ALL_ADMIN_UPLOAD_XCX_STATUS_GET:'ALL_ADMIN_UPLOAD_XCX_STATUS_GET',
    ALL_ADMIN_UPLOAD_XCX_STATUS_GET_SUCCESS:'ALL_ADMIN_UPLOAD_XCX_STATUS_GET_SUCCESS',
    //更新小程序基本信息
    ALL_ADMIN_XCX_MSG_UPDATE:'ALL_ADMIN_XCX_MSG_UPDATE',

    //系统home的相关指令
    H_ROOM_TYPE_LIST:'H_ROOM_TYPE_LIST',

}
export default constants;