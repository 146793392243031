import axios_pro,{axios_config} from '@/mc/axios_pro';

export function isInitHad(params){
    return new Promise((resolve,reject)=>{
        axios_pro.post("/home/customer/isInitHad",params)
            .then(function(res){
                resolve(res.data)
            }).catch(function (err) {
            reject(err)
        })
    })
}
//
export function downLoadVersion(params){
    return new Promise((resolve,reject)=>{
        axios_pro.post("/home/customer/downLoadVersion",params)
            .then(function(res){
                resolve(res.data)
            }).catch(function (err) {
            reject(err)
        })
    })
}

export function  downLoadVersion2(params){
    let canshu={
        // url:axios_config.baseURL+'/printOrder/downLoadFile',
        url:axios_config.baseURL+'/home/customer/downLoadVersion',
        filename:params.filename,
        params:params,
        progress:params.progress,
        success:params.success
    }
    progressDownLoad(canshu);
    function progressDownLoad({url,filename,params,progress,success}){
        console.log(params)
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        //监听进度事件
        xhr.addEventListener("progress", function (evt) {
            if(progress) try{ progress(evt); }catch(e){}
        }, false);

        xhr.responseType = "blob";
        xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded; charset=UTF-8");
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                if (typeof window.chrome !== 'undefined') {
                    // Chrome version
                    var link = document.createElement('a');

                    //获取服务器文件名-自写
                    console.log(xhr.getResponseHeader('content-disposition'))
                    let fff=xhr.getResponseHeader('content-disposition').split('=')[1];
                    let fff_decode=decodeURI(fff);

                    link.href = window.URL.createObjectURL(xhr.response);
                    link.download = fff_decode;
                    link.click();
                } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE version
                    //获取服务器文件名-自写
                    let fff=xhr.getResponseHeader('content-disposition').split('=')[1];
                    let fff_decode=decodeURI(fff);

                    var blob = new Blob([xhr.response], { type: 'application/force-download' });
                    window.navigator.msSaveBlob(blob, fff_decode);
                } else {
                    // Firefox version
                    //获取服务器文件名-自写
                    let fff=xhr.getResponseHeader('content-disposition').split('=')[1];
                    let fff_decode=decodeURI(fff);

                    var file = new File([xhr.response], fff_decode, { type: 'application/force-download' });
                    window.open(URL.createObjectURL(file));
                }
                if(success) try{ success(xhr); }catch(e){}
            }
        };
        // FormData
        //var formData = new FormData();
        var paramsStr = '';
        if(params) for (var key in params) paramsStr += '&'+key+'='+params[key];
        if(paramsStr) paramsStr = paramsStr.substring(1);
        xhr.send(paramsStr);
    }
}