import React,{Component} from 'react';
import styles from './Modal.less';
import { Spin  } from 'antd';

// mc
import {connect} from 'react-redux';
import Actions from '@/mc/actions/Actions';

function mapStateToProps(state) {
    return {
        global_modal_isShow: state.global.global_modal_isShow
    };
}


class Index extends Component{
    render() {
        let {global_modal_isShow}=this.props;
        let params={
            display:'none'
        }
        if(global_modal_isShow){
            params={
                display:'flex'
            }
        }
        return (
            <div className={styles.bg} style={params}>
                <Spin size="large" tip="请求中，请稍后..."/>
            </div>
        )
    }
}

export default  connect(mapStateToProps)(Index);