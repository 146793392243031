import constants from '@/mc/constants';
export function global_modal_show(){
    return {
        type:constants.GLOBAL_MODAL_SHOW
    }
}
export function global_modal_unshow(){

    return {
        type:constants.GLOBAL_MODAL_UNSHOW
    }
}