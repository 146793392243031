/**
 * Created by sky on 2019/3/28.
 */
import { createStore,combineReducers,applyMiddleware,compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import init from './init';
import mySaga from './sagas/Sagas'

import reducers from './reducers/Reducers';

const sagaMiddleware = createSagaMiddleware();
// let store = createStore(
//     combineReducers(reducers),
//     init,
//     compose(applyMiddleware(sagaMiddleware),window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__(): undefined)
// );
let store = createStore(
    combineReducers(reducers),
    init,
    compose(applyMiddleware(sagaMiddleware))
);


sagaMiddleware.run(mySaga);
export default store;
