import React,{Component} from 'react';
import styles from './DownLoadWeb.less'
import qs from 'qs';
//antd组件
import {
    message,
    Table,
    Avatar,
    Card,
    Switch,
    Input,
    Select,
    DatePicker,
    Button,
    Tag,
    Progress,
    InputNumber,
    PageHeader,
    Modal,
    Tabs,
    Badge, List, Skeleton, Form, Icon,Spin
} from 'antd';

// mc
import {connect} from 'react-redux';
import Actions from '@/mc/actions/Actions';
import Api from '@/mc/api/Api';


//antd组件细化
const { TabPane } = Tabs;

function mapStateToProps(state) {
    return {

    };
}

class Index extends Component{
    constructor(props){
        super(props);
        let {admin_msg} = props;
        this.state={

        }
    }
    componentWillMount() {
        Api.downLoadWeb.isInitHad({}).then((rs)=>{
            if(rs.code == 0){
                Modal.info ({
                    title:'提示',
                    content:'系统已经初始化过，正在跳转'
                })
                window.location.href = rs.data;
            }else{
                this.downLoadVersion();
            }
        })
    }
    downLoadVersion=()=>{
        let params={
            'aa':'3330',
            bb:'99',
            progress:(e)=>{
                console.log(e)
            }
        }
        Api.downLoadWeb.downLoadVersion(params).then((rs)=>{
            console.log(rs);
            if(rs.code == 0){
                Modal.info ({
                    title:'提示',
                    content:'初始化成功，耗时： '+rs.data['use_time']+' 秒',
                    okText:'前往系统',
                    onOk:()=>{
                        window.location.href = rs.data['version_url'];
                    }
                })

            }else{
                Modal.error({
                    title:'提示',
                    content:rs.msg
                })
            }
        })
    }

    render() {
        return (
            <div className={styles.content} >
                <div className={styles.content_box}>
                    <h3>初次使用，需要初始化，根据您的服务器性能，约1分钟左右，完成后将自动跳转，请耐心等待...</h3>
                    <div style={{width:'100%',marginTop:20}}>
                        <Spin size={"large"}> </Spin>
                        {/*<Progress type="circle" percent={50} />*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Index);