/**
 * Created by sky on 2019/4/4.
 */
import axios from 'axios';
import $ from 'jquery';
//antd组件
import {message} from 'antd';

let baseURL = window.location.origin+`/index.php`;

let axios_config_content={
    baseURL:baseURL,
    timeout: 60*1000,
}
/**
 * 线上环境
 */
const axios_pro=axios.create(axios_config_content);
/**
 * 开发环境
 * @type {AxiosInstance}
 */
// const axios_pro=axios.create({timeout: 60*1000,});

// 添加请求拦截器
axios_pro.interceptors.request.use(function (config) {
    if(localStorage['admin_msg']){
        let admin_msg=JSON.parse(localStorage['admin_msg']);
        config.headers.AUTHTOKEN=admin_msg['user_token']
    }
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// // 添加响应拦截器
axios_pro.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    if(response.data.code == 886){
        // 886 代码代表登陆过期了
        window.location.reload();
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});


export const axios_config={
    ...axios_config_content
};
export default axios_pro;