/**
 * Created by sky on 2019/3/29.
 */
import constants from '@/mc/constants';
import {message} from "antd";

export function admin (state,action){
    if(action.type === constants.ADMIN_LOGIN_SUCCESS){
        let data=action.data;
        if(data.code==0){
            localStorage['admin_msg']=JSON.stringify(data.data);
            return {
                ...state,
                admin_msg:data.data
            }
        }else{
            message.info(data.msg);
            return {
                ...state,
                admin_msg:null
            }
        }
    }
    if(action.type === constants.ADMIN_LOGIN_STATUS_CHECK_SUCCESS){
        let data=action.data;
        if(data.code==0){
            localStorage['admin_msg']=JSON.stringify(data.data);
            return {
                ...state,
                admin_msg:data.data,
            }
        }else{
            message.info(data.msg);
            return {
                ...state,
                admin_msg:null
            }
        }
    }
    if(action.type === constants.ADMIN_LOGIN_OUT_SUCCESS){
        let data=action.data;
        if(data.code==0){
            return {
                ...state,
                admin_msg:null
            }
        }else{
            message.info(data.msg);
            return {
                ...state
            }
        }
    }
    return {
        ...state
    }
}