import constants from '@/mc/constants';
import {message} from "antd";

export function allAdmin (state,action){
    if(action.type === constants.SHOP_GET_SUCCESS){
        let data=action.data;
        if(data.code==0){
            return {
                ...state,
                shop_list:data
            }
        }else{
            message.info(data.msg);
            return {
                ...state
            }
        }
    }
    if(action.type === constants.ALL_ADMIN_AUTH_GET_SUCCESS){
        let data=action.data;
        if(data.code==0){
            return {
                ...state,
                all_auth:data.data
            }
        }else{
            message.info(data.msg);
            return {
                ...state
            }
        }
    }
    //上传小程序成功
    if(action.type === constants.ALL_ADMIN_UPLOAD_XCX_SUCCESS){
        let data=action.data;
        if(data.code==0){
            return {
                ...state,
                tool_login_image:data.data
            }
        }else{
            message.info(data.msg);
            return {
                ...state
            }
        }
    }
    //获取小程序上传结果成功
    if(action.type === constants.ALL_ADMIN_UPLOAD_XCX_STATUS_GET_SUCCESS){
        let data=action.data;
        if(data.code==0){
            clearInterval(window.timer_upload);
            return {
                ...state,
                upload_xcx_status:true
            }
        }else{
            message.info(data.msg);
            return {
                ...state
            }
        }
        return {
            ...state
        }
    }
    return {
        ...state
    }
}