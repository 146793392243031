import constants from '@/mc/constants';
import Api from '@/mc/api/Api';
export function getShop(params){
    return {
        type:constants.SHOP_GET,
        data:params
    }
}
export function getShopSuccess(data){
    return {
        type:constants.SHOP_GET_SUCCESS,
        data:data
    }
}

//获取客户权限
export function getCustomerAuth(params){
    return {
        type:constants.ALL_ADMIN_AUTH_GET,
        data:params
    }
}
export function getCustomerAuthSuccess(data){
    return {
        type:constants.ALL_ADMIN_AUTH_GET_SUCCESS,
        data:data
    }
}


//上传小程序
export function uploadXcx(params){
    return {
        type:constants.ALL_ADMIN_UPLOAD_XCX,
        data:params
    }
}
export function uploadXcxSuccess(data){
    return {
        type:constants.ALL_ADMIN_UPLOAD_XCX_SUCCESS,
        data:data
    }
}
//检测上传小程序状态
export function uploadXcxStatus(params){
    return {
        type:constants.ALL_ADMIN_UPLOAD_XCX_STATUS_GET,
        data:params
    }
}
export function uploadXcxStatusSuccess(data){
    return {
        type:constants.ALL_ADMIN_UPLOAD_XCX_STATUS_GET_SUCCESS,
        data:data
    }
}
//更新小程序基本信息
export function updateXcxBasicMsg(params) {
    let res=Api.allAdmin.updateXcxBasicMsg(params);
    res.then(function (data) {
        console.log(data);
        return {
            type:constants.ALL_ADMIN_XCX_MSG_UPDATE,
            data:data
        }
    })
}