import { call, put, takeEvery, takeLatest ,delay} from 'redux-saga/effects';
import Api from '@/mc/api/Api';

import Actions from '@/mc/actions/Actions';
import {message} from 'antd';

export function* getShop(action) {
    try {
        const res = yield call(Api.allAdmin.getShop, action.data);
        yield put(Actions.allAdmin.getShopSuccess(res));
    } catch (e) {
        message.info('请检查网络');
    }
}
export function* getCustomerAuth(action) {
    try {
        const res = yield call(Api.allAdmin.getCustomerAuth, action.data);
        yield put(Actions.allAdmin.getCustomerAuthSuccess(res));
    } catch (e) {
        message.info('请检查网络');
    }
}
//上传小程序
export function* uploadXcx(action) {
    try {
        const res = yield call(Api.allAdmin.uploadXcx, action.data);
        yield put(Actions.allAdmin.uploadXcxSuccess(res));
    } catch (e) {
        message.info('请检查网络');
    }
}

//检测上传小程序是否成功
export function* uploadXcxStatus(action) {
    try {
        const res = yield call(Api.allAdmin.uploadXcxStatus, action.data);
        yield put(Actions.allAdmin.uploadXcxStatusSuccess(res));
    } catch (e) {
        message.info('请检查网络');
    }
}