/**
 * Created by sky on 2019/4/4.
 */
import home from './home/home.actions'
import * as global from './global'
import * as admin from './admin'
import * as allAdmin from './allAdmin'


const Actions={
    ...home,global,
    admin,allAdmin
}

export default Actions;