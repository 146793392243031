/**
 * Created by sky on 2019/3/29.
 */
import constants from '@/mc/constants';
export function login(params){
    return {
        type:constants.ADMIN_LOGIN,
        data:params
    }
}
export function loginSuccess(data){
    return {
        type:constants.ADMIN_LOGIN_SUCCESS,
        data:data
    }
}
export function checkLoginStatus(params){
    return {
        type:constants.ADMIN_LOGIN_STATUS_CHECK,
        data:params
    }
}
export function checkLoginStatusSuccess(data){
    return {
        type:constants.ADMIN_LOGIN_STATUS_CHECK_SUCCESS,
        data:data
    }
}
export function loginOut(params){
    return {
        type:constants.ADMIN_LOGIN_OUT,
        data:params
    }
}
export function loginOutSuccess(data){
    return {
        type:constants.ADMIN_LOGIN_OUT_SUCCESS,
        data:data
    }
}