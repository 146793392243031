/**
 * Created by sky on 2019/3/28.
 */
import home from './home/home.reducers'
import {global} from './global'
import {admin} from './admin';
import {allAdmin} from './allAdmin';


var Reducers={
    ...home,global,
    admin,allAdmin
};
export default Reducers;
