import constants from '@/mc/constants';
let init={
    global_modal_isShow:false
}
export function global (state=init,action){
    if(action.type === constants.GLOBAL_MODAL_SHOW){
        return {
            ...state,
            global_modal_isShow:true
        }
    }
    if(action.type === constants.GLOBAL_MODAL_UNSHOW){
        return {
            ...state,
            global_modal_isShow:false
        }
    }
    return {
        ...state
    }
}