/**
 * Created by sky on 2019/3/29.
 */
import * as downLoadWeb from './downLoadWeb';

const Api={
    downLoadWeb
}
export default Api;
// var formData = new FormData();
// for(var key in params){
//     formData.append(key,params[key]);
// }