import { call, put, takeEvery, takeLatest ,delay} from 'redux-saga/effects';
import Api from '@/mc/api/Api';

import Actions from '@/mc/actions/Actions';
import {message} from 'antd';

export function* login(action) {
    try {
        yield put(Actions.global.global_modal_show());
        const res = yield call(Api.admin.login, action.data);
        yield put(Actions.global.global_modal_unshow());
        yield put(Actions.admin.loginSuccess(res));
    } catch (e) {
        message.info('请检查网络');
    }
}
export function* checkLoginStatus(action) {
    try {
        const res = yield call(Api.admin.checkLoginStatus,action.data);
        if(action.data){
            action.data(res);
        }
        yield put(Actions.admin.checkLoginStatusSuccess(res));
    } catch (e) {
        message.info('请检查网络');
    }
}
export function* loginOut(action) {
    try {
        yield put(Actions.global.global_modal_show());
        const res = yield call(Api.admin.loginOut,action.data);
        yield put(Actions.global.global_modal_unshow());
        yield put(Actions.admin.loginOutSuccess(res));
    } catch (e) {
        message.info('请检查网络');
    }
}