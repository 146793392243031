/**
 * Created by sky on 2019/3/29.
 */
import { call, put, takeEvery, takeLatest ,delay} from 'redux-saga/effects';

import constants from '../constants';

import * as admin from './admin'
import * as allAdmin from './allAdmin'


function* mySaga(){
    //总管理账号相关
    yield takeLatest(constants.SHOP_GET, allAdmin.getShop);
    yield takeLatest(constants.ALL_ADMIN_AUTH_GET, allAdmin.getCustomerAuth);
    // 上传小程序
    yield takeLatest(constants.ALL_ADMIN_UPLOAD_XCX, allAdmin.uploadXcx);
    //检测上传状态
    yield takeLatest(constants.ALL_ADMIN_UPLOAD_XCX_STATUS_GET, allAdmin.uploadXcxStatus);

    //分店账号相关

    // 管理员相关
    yield takeLatest(constants.ADMIN_LOGIN, admin.login);
    yield takeLatest(constants.ADMIN_LOGIN_STATUS_CHECK, admin.checkLoginStatus);
    yield takeLatest(constants.ADMIN_LOGIN_OUT, admin.loginOut);


}

export default mySaga;
