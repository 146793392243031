import React, { Component } from 'react';
import styles from './App.less';
import {BrowserRouter as Router, Route, Link ,Switch} from 'react-router-dom'
import moment from 'moment'
import $ from 'jquery'
import {
    Button,
    ConfigProvider, Result
} from 'antd'
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';

import Modal from './pages/Modal/Modal'
import DownLoadWeb from './pages/DownLoadWeb';

// mc
import {connect, Provider} from 'react-redux';
import Actions from '@/mc/actions/Actions';

moment.locale('zh-cn');

function mapStateToProps(state) {
    return {
        admin_msg: state.admin.admin_msg
    };
}

class App extends Component {
    constructor(props){
        super(props);
    }
    componentWillMount() {
        // let {dispatch,history,admin_login_status,admin_msg}=this.props;
        // dispatch(Actions.admin.checkLoginStatus());
    }

    render() {
        // let pathname;
        // if(sessionStorage['pathname']){
        //     pathname=sessionStorage['pathname'];
        // }else{
        //     pathname=window.location.pathname;
        //     sessionStorage['pathname'] = pathname;
        // }
        sessionStorage.clear();

        return (
            <Router >
                <Route strict path="/" render={()=>{
                    return (
                        <ConfigProvider locale={zhCN}>
                            <div style={{width:'100%',height:'100%',position:'relative'}}>
                                <DownLoadWeb/>
                                <Modal/>
                            </div>
                        </ConfigProvider>
                    );
                }} />
            </Router>
        );
    }
}

export default connect(mapStateToProps)(App);
